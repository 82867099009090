import React, { useState } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { contactUs } from '../../api';

export default function ContactModal({ Close, setSupportModalShow }) {

    const [errorMessage, setErrorMessage] = useState()

    const formik = useFormik({
        initialValues: {
            email: '',
            message: '',
        },
        onSubmit: () => {
            const email = formik.values.email
            const message = formik.values.message;
            const func = contactUs(email, message)
            // console.log(func)
            Close(true)
            setSupportModalShow(false)
        }
    })



    return (
        <Container>
            <p style={{ color: 'red' }}>
                {errorMessage}
            </p>
            <Form onSubmit={formik.handleSubmit}>
                <p style={{ color: 'white', fontSize: 18 }}>To: <span>Support@cashabet365.com</span></p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        type="email"
                        placeholder="From"
                        name="email" />
                    <Form.Control
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        as="textarea" rows={5}
                        placeholder="Message"
                        name="message"
                        style={{ marginTop: 8 }} />
                </Form.Group>
                <Button type="submit" className='submit'>
                    Contact Us
                </Button>
            </Form>
        </Container>
    )
}
