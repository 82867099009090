import React from 'react'
import { Container } from 'react-bootstrap'
import Cookies from 'universal-cookie';
import UpcomingData from './upcomingData';


export default function Upcoming() {
    const cookie = new Cookies();

    return (
        <>
            <Container>
                <h4 style={{ color: '#fff', marginBottom: 8, fontWeight: 600, marginTop: 32 }}>Upcoming Games</h4>
                <div style={{ display: 'flex' }} >
                    <UpcomingData signin={cookie.get('token') ? null : true} />
                </div>

                <br></br>
            </Container>
        </>
    )
}
