import React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Cookies from 'universal-cookie';

export default function DropDown() {
    const cookie = new Cookies()

    const logout = () => {
        cookie.remove('token')
        window.location.reload()
    }
    const popover = (
        <Popover id="popover-basic">
            <Popover.Body style={{ cursor: 'pointer' }} onClick={logout}>
                <p style={{ margin: 0 }}>Log out</p>
            </Popover.Body>
        </Popover>
    );
    return (
        <>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                <Button variant="none" style={{ color: 'white', marginRight: 32 }}><strong>{cookie.get('balance')} {cookie.get('currency')}</strong> - {cookie.get('username')}</Button>
            </OverlayTrigger>
        </>
    )
}
