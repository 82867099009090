import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.css'
import { sidebarData1, sidebarData2 } from './sidebarData'
import Modale from '../modal';
import Cookies from 'universal-cookie';
import { Badge } from 'react-bootstrap';
import Marquee from "react-fast-marquee";


export default function SideBar() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    return (
        <div>
            <Container fluid>
                <Row style={{ textAlign: 'center' }} lg={5} md={3} sm={2} xs={1}>
                    {sidebarData1?.map((data) =>
                        <Col key={data.id} pill bg="info" className='typesBadge' onClick={() => setModalShow(true)}>
                            <p>{data.title}</p>
                        </Col>
                    )}
                </Row>
                <Row style={{ marginTop: 24, textAlign: 'center', marginBottom: 32 }} lg={5} md={3} sm={2} xs={1}>
                    {sidebarData2?.map((data) =>
                        <Col key={data.id} pill bg="info" className='typesBadge' onClick={() => setModalShow(true)}>
                            <p>{data.title}</p>
                        </Col>
                    )}
                </Row>
            </Container>



            {
                cookie.get('token') ?
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    // signin={true}
                    />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                    />

            }
        </div>

    )
}
