import React, { useState } from 'react'
// import { Col, Container, Row } from 'react-bootstrap'
// import { AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';
import { FaInstagram, FaFacebookF, FaTwitter } from 'react-icons/fa';


import Modale from '../modal';
import Cookies from 'universal-cookie';
import './style.css'

export default function Footer() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();


    const links = [
        {
            id: 1,
            title: 'Home'
        },
        {
            id: 2,
            title: 'Bet Now'
        },
        {
            id: 3,
            title: 'Sports'
        },
        {
            id: 4,
            title: 'Upcoming Games'
        },
        {
            id: 5,
            title: 'Contact Us'
        },
    ]

    return (
        <div style={{ backgroundColor: '#000' }}>
            <div className='FooterContainer'>
                {/* <h4>Cashabet365.com</h4> */}
                <div className='footerLogo'></div>
                <div className='linksContainer'>
                    {
                        links?.map((item) => (
                            <p key={item.id} style={{ opacity: '70%', cursor: 'pointer', marginRight: 16, color: 'white' }} onClick={() => setModalShow(true)}>{item.title}</p>
                        ))
                    }
                </div>
                {/*  <div className="socialMediaContainer">
                    <div className='socialMediaIcons'>
                        <FaInstagram size={40} onClick={() => setModalShow(true)} />
                    </div>
                    <div className='socialMediaIcons'>
                        <FaFacebookF size={30} onClick={() => setModalShow(true)} />
                    </div>
                    <div className='socialMediaIcons' >
                        <FaTwitter size={40} onClick={() => setModalShow(true)} />
                    </div>
                </div>*/}
                <div className="copyRight" >
                    <p style={{ margin: 0, paddingTop: 0 }}>Copyright © 2022 - All right reserved.</p>
                </div>
            </div>

            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={cookie.get('token') ? null : true}
            />
        </div>
    )
}