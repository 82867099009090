import { Carousel, Col, Container, Row } from 'react-bootstrap'
import React from 'react'
import './style.css'
import News from '../news';



export default function Slideshow({ news, setNews }) {

    return (
        <Container style={{ marginTop: ' 97px', padding: 0 }} >
            <Row style={{ padding: 0 }}>
                <Col lg={7}>
                    <Carousel indicators={false} interval={2000} className="CaouselContainer">
                        <Carousel.Item style={{ width: '100%' }} >
                            <div
                                className=" carouselItem"
                                style={{
                                    backgroundImage: `url(${require("../../assets/images/1.webp")})`,
                                }}
                            ></div>
                        </Carousel.Item>
                        <Carousel.Item style={{ width: '100%' }} >
                            <div
                                className=" carouselItem"
                                style={{
                                    backgroundImage: `url(${require("../../assets/images/2.webp")})`,
                                }}
                            ></div>
                        </Carousel.Item>
                        <Carousel.Item style={{ width: '100%' }} >
                            <div
                                className=" carouselItem"
                                style={{
                                    backgroundImage: `url(${require("../../assets/images/3.webp")})`,
                                }}
                            ></div>
                        </Carousel.Item>
                        <Carousel.Item style={{ width: '100%' }} >
                            <div
                                className=" carouselItem"
                                style={{
                                    backgroundImage: `url(${require("../../assets/images/4.webp")})`,
                                }}
                            ></div>
                        </Carousel.Item>
                        <Carousel.Item style={{ width: '100%' }} >
                            <div
                                className=" carouselItem"
                                style={{
                                    backgroundImage: `url(${require("../../assets/images/5.webp")})`,
                                }}
                            ></div>
                        </Carousel.Item>
                        <Carousel.Item style={{ width: '100%' }} >
                            <div
                                className=" carouselItem"
                                style={{
                                    backgroundImage: `url(${require("../../assets/images/6.webp")})`,
                                }}
                            ></div>
                        </Carousel.Item>
                    </Carousel>
                </Col>
                <Col>
                    <News news={news} setNews={setNews} />
                </Col>
            </Row>

        </Container>

    )
}
