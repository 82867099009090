// import React from 'react';

export const sidebarData1 = [
    {
        id: 1,
        title: "Point Spreads",
    },
    {
        id: 2,
        title: "Money Lines",
    },
    {
        id: 3,
        title: "Totals",
    },
    {
        id: 4,
        title: "European Prices",
    },
    {
        id: 5,
        title: "Quarter Lines",
    },
]


export const sidebarData2 = [
    {
        id: 1,
        title: "Half-time Lines",
    },
    {
        id: 2,
        title: "Parlays",
    },
    {
        id: 3,
        title: "Progressive Parlays",
    },
    {
        id: 4,
        title: "Teasers",
    },
    {
        id: 5,
        title: "If Bets",
    },
]

