import React from 'react'
import { Container } from 'react-bootstrap'
import Cookies from 'universal-cookie';
import CanceledGamesData from './canceledgamesData';


export default function CanceledGames() {
    const cookie = new Cookies();

    return (
        <>
            <Container>
                <div style={{ display: 'flex' }} >
                    <CanceledGamesData signin={cookie.get('token') ? null : true} />
                </div>
                <br></br>
            </Container>
        </>
    )
}
