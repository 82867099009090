import React from 'react'
// import axios from 'axios';
import { useState } from 'react'
// import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Modale from '../modal';
// import Marquee from "react-fast-marquee";
// import Cookies from 'universal-cookie';

export default function NewsData({ signin, news, setNews }) {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            {/* <Marquee gradient={false} speed={100} pauseOnHover={true}> */}
            {news?.map((data, index) => (
                <>
                    <div className="newsDetailsContainer" onClick={() => setModalShow(true)}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className='newsImageContainer' style={{ backgroundImage: `url(${data.enclosures[0].url})` }} alt="news"></div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', marginLeft: 8 }}>
                                <p className="newsTitle" style={{ fontWeight: 600, lineHeight: 1.3, height: '4rem', color: 'white' }}>{data.title}</p>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <p className='dateTypeNews'>{moment(data.published).format('dddd DD MMMM yyyy HH:MM')}</p>
                                    <p className='dateTypeNews' >{data.category}</p>
                                </div>
                            </div>
                        </div>
                        <div className='hl'></div>
                    </div>

                </>

            ))}
            {/* </Marquee> */}
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={signin}
            />
        </>
    )
}
