import { Container } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import NewsData from './newsData';
import './style.css'

export default function News({ news, setNews }) {
    const cookie = new Cookies();


    return (
        <>
            <Container>
                {/* <h4 style={{ color: '#3a3a3c', fontWeight: 600 }}>What's new</h4> */}
                <div className='newsContainer' >
                    <NewsData signin={cookie.get('token') ? null : true} news={news} setNews={setNews} />
                </div>
                <br></br>
            </Container>
        </>
    )
}
