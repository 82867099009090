import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Col, Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import Modale from '../modal';
import './style.css'
import shuffledArray from '../../hook/shuffledArray';
// import Cookies from 'universal-cookie';

export default function UpcomingData(props) {
    const [upcoming, setUpcoming] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        axios.get('/api/results')
            .then(response => {
                console.log(response.data)
                setUpcoming(response.data.data[0].results)
            })
            .catch(error => console.log(error));

    }, []);

    var shuffledarray = shuffledArray(upcoming);

    return (
        <>

            <Col className='upcomingTableContainer' >
                <Table responsive="sm" borderless={true} >
                    <thead>
                        <tr className='tableHead'>
                            <th>Date</th>
                            <th>League</th>
                            <th>Home</th>
                            <th>Away</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            shuffledarray.filter((data) => data.status !== 'canceled').map((data, index) => (
                                <tr key={index} className='trBody'>
                                    <td className='td1'>
                                        {/* {moment(data.lastUpdated).tz("USA/California").format('DD-MM-YYYY')} */}
                                        {moment().format('dddd DD MMMM yyyy HH:MM')}
                                    </td>
                                    <td style={{ fontSize: 14, fontWeight: 600 }}>
                                        {data.details.league}
                                    </td>
                                    <td>
                                        <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500, margin: 0, borderRadius: 0 }}>{data.teams.home.team}</p>
                                    </td>
                                    <td className='td4'>
                                        <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500, margin: 0 }}>{data.teams.away.team}</p>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Col>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={props.signin}
            />
        </>
    )
}
